
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "react-quill/dist/quill.snow.css";
import "styles/editor.css";
import "../styles/globals.css";
import "../styles/games.css";
import { Preloader, Redirection } from "components/app";
import { CheatProtection } from "components/app/cheat-protection";
import { DialogProvider, DialogRoot } from "components/atomic/dialog";
import { InjectionProvider } from "components/atomic/injection";
import { AdminLayout, AuthLayout, EmployerLayout, EmptyLayout, PublicLayout, } from "components/layout";
import { useTranslation } from "hooks/translation";
import Link from "next/link";
import { QueryClient, QueryClientProvider } from "react-query";
import { PublicRoutes, userRoot } from "routes/routes";
import { setGlobalCacheVersion } from "utility/browser-cache/browser-cache";
import { match } from "utility/match";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { BuildChecker } from "components/app/build-checker";
import { GameLayout } from "components/layout/game-layout";
import { DetectionProvider } from "utility/detection";
import { useEffect, useState } from "react";
type AppPropsWithLayout = AppProps & {
    Component: NextPage;
};
const underDevelopment = process.env.NODE_ENV === "development";
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: parseInt(
            // 10 Minutes
            String(process.env.NEXT_PUBLIC_REACT_QUERY_STALE_TIME || 600000)),
            refetchInterval: parseInt(
            // 10 Minutes
            String(process.env.NEXT_PUBLIC_REACT_QUERY_REFRESH_INTERVAL || 600000)),
            cacheTime: parseInt(
            // 1 Hour
            String(process.env.NEXT_PUBLIC_REACT_QUERY_CACHE_TIME || 3600000)),
            refetchIntervalInBackground: false,
            retry: false,
        },
    },
});
setGlobalCacheVersion(1);
function MyApp({ Component, pageProps, router }: AppPropsWithLayout) {
    const { $t, lang } = useTranslation();
    const Layout = match({
        404: AuthLayout,
        500: AuthLayout,
        "admin/debug": underDevelopment ? PublicLayout : AdminLayout,
        admin: AdminLayout,
        "employer/set-member-password": AuthLayout,
        "employer/games": GameLayout,
        "employer/assessments/preview/test": PublicLayout,
        employer: EmployerLayout,
        "auth/demo": EmptyLayout,
        auth: AuthLayout,
        register: AuthLayout,
        games: GameLayout,
    }, (path) => router.pathname.startsWith(`/${path}`)) ?? PublicLayout;
    const isJob = router.pathname.startsWith("/jobs");
    const isPublicCompany = router.pathname.startsWith("/company");
    const isJobApplication = router.pathname.startsWith("/jobs/application");
    const isSharedTest = router.pathname.startsWith("/test/shared-link");
    const isJobTest = router.pathname.startsWith("/applicant/[applicantId]/tests/[jobTestId]");
    const isBlankPage = router.pathname.startsWith("/admin/blank");
    const isLoginPage = router.pathname.startsWith(PublicRoutes.Login);
    const isReferencePage = router.pathname.startsWith("/reference");
    const isGamePage = router.pathname.startsWith("/games");
    console.log({ isReferencePage });
    const authSkipped = [AuthLayout, EmptyLayout].includes(Layout) && !isLoginPage;
    const authOptional = (isJob && !isJobApplication) ||
        isBlankPage ||
        isLoginPage ||
        isGamePage ||
        isPublicCompany ||
        isReferencePage;
    const authMandatory = !(authSkipped || authOptional);
    return (<QueryClientProvider client={queryClient}>
      <InjectionProvider Link={Link} router={router} language={lang} translate={(key, context) => $t(`common:${key}`, context)}>
        <DetectionProvider>
          <DialogProvider throughRoutes={["/admin/blank"]}>
            <Redirection skipAuth={authSkipped} requireAuth={!authOptional} userRoot={userRoot}>
              <BuildChecker />
              <Preloader hasAuth={authMandatory}/>
              <CheatProtection enabled={isSharedTest || isJobTest}>
                <main>
                  <Layout>
                    <Component {...pageProps}/>
                  </Layout>
                </main>
              </CheatProtection>
            </Redirection>
            <DialogRoot />
          </DialogProvider>
        </DetectionProvider>
      </InjectionProvider>
    </QueryClientProvider>);
}
const __Next_Translate__Page__193481eb3e2__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__193481eb3e2__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  